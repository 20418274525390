<template>
  <form id="upload-slip-form">
    <b-button type="is-primary" class="w-full" @click="click">Upload Slip</b-button>
    <input type="file" name="File" class="hidden" id="uploadslipinput" @change="inputChange" accept="image/*">
    <b-modal v-model="showCameraOption" has-modal-card can-cancel>
      <div class="modal-card bg-white" style="width: auto">
        <button class="p-5 text-center border-b" @click="selectImageCordova('camera')">
          Take photo
        </button>
        <button class="p-5 text-center" @click="selectImageCordova('gallery')">
          Select from gallery
        </button>
      </div>
    </b-modal>
    <preview-image :img="value" v-model="preview">
      <template #footer>
        <div class="p-4">
          <b-button expanded type="is-primary" @click="uploadConfirmation">Upload</b-button>
        </div>
      </template>
    </preview-image>
  </form>
</template>

<script>
import PreviewImage from './PreviewImage'
export default {
  components: {
    PreviewImage
  },
  props: {
    id: String,
    uri: String
  },
  data () {
    return {
      files: 0,
      value: null,
      showCameraOption: false,
      preview: false
    }
  },
  methods: {
    click () {
      if (this.isCordova) {
        this.showCameraOption = true
      } else {
        document.getElementById('uploadslipinput').click()
      }
    },
    selectImageCordova (type = 'camera') {
      this.showCameraOption = false
      if (this.isCordova) {
        window.navigator.camera.getPicture((img) => {
          this.viewImage('data:image/jpeg;base64,' + img)
        }, (err) => {
          alert(err)
        }, {
          sourceType: type === 'camera' ? 1 : 0,
          destinationType: 0
        })
      }
    },
    inputChange (e) {
      if (!e.target.files.length) return

      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        this.viewImage(reader.result)
      }
    },
    viewImage (img) {
      this.value = img
      this.preview = true
    },
    closeModal () {
      this.preview = false
    },
    uploadConfirmation () {
      this.$buefy.dialog.confirm({
          message: 'Resi anda telah di upload',
          type: 'is-primary',
          onConfirm: async () => {
            this.upload()
          }
      })
    },
    async upload () {
      const loading = this.$buefy.loading.open()
      try {
        await this.$baseApi.post(this.uri || this.$route.path, { Oid: this.id, File: this.value, Extension: 'jpg' })
        this.$emit('done')
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style>

</style>
